import React from "react"
import FitxaArtistica from "./FitxaArtistica";
import Gallery from "./Gallery";
import Sponsors from "./Sponsors";

const ProjectDetailComponent = ({project}) => {

    function isActive(button) {
        return typeof button.status === "string" && button.status === "ACTIVE" && button.text.length > 0
    }

    function isNotClickable(button) {
        return typeof button.status === "string" && button.status === "INACTIVE" && button.text.length > 0
    }

    function hasDescomptes(pricing) {
        return pricing.pis !== "0.0€"
    }

    return (
      <div>
        <div className="border-b border-l border-r border-blu">
          <h1 className="p-6 lg:p-6  border-blu text-5xl lg:text-6xl font-brik text-blue text-center lg:text-start">
            {project.title}
          </h1>
        </div>
        <div className="flex lg:flex-row flex-col border-l border-r border-blu lg:h-auto">
          {/* Imagen del espectáculo */}
          <div className="lg:w-2/3 w-full lg:h-full ">
            <img
              src={project.imageUrl}
              alt={project.title}
              className="w-full h-full object-cover"
              style={{ height: "100%", maxHeight: "600px" }} // La imagen se adapta al contenedor
            />
          </div>

          {/* Información clave a la derecha */}
          <div className="tracking-normal text-gray text-xl lg:w-1/3 w-full bg-blue text-white p-8 flex flex-col justify-between lg:h-auto">
            {/* Ficha de información organizada en grid */}
            <div className="grid grid-cols-2 gap-y-6 gap-x-6">
              {/* Hora y Lugar */}
              <div className="col-span-2">
                <h2 className="text-3xl font-brik uppercase">HORA I LLOC</h2>
                <p className="text-base">{project.date}</p>
                <p className="text-base">{project.date2}</p>
                {project.location ? (
                  <p className="text-base">{project.location.name}</p>
                ) : (
                  <div />
                )}
              </div>

              {/* AUTORIA y MÚSICA en la misma fila */}
              <div>
                <h2 className="text-2xl font-brik">AUTORIA</h2>
                <p className="text-base">{project.author}</p>
              </div>
              <div>
                <h2 className="text-2xl font-brik">MÚSICA</h2>
                <p className="text-base">{project.music}</p>
              </div>

              {/* DIRECCIÓ y COREO en la misma fila */}
              <div>
                <h2 className="text-2xl font-brik">DIRECCIÓ</h2>
                <p className="text-base">{project.direction}</p>
              </div>
              <div>
                <h2 className="text-2xl font-brik">COREO</h2>
                <p className="text-base">{project.coreo}</p>
              </div>

              {/* DURADA y IDIOMA en la misma fila */}
              <div>
                <h2 className="text-2xl font-brik">DURADA</h2>
                <p className="text-base">{project.duration}</p>
              </div>
              <div>
                <h2 className="text-2xl font-brik">IDIOMA</h2>
                <p className="text-base">{project.language}</p>
              </div>

              {/* EDAT en una fila independiente */}
              <div>
                <h2 className="text-2xl font-brik">EDAT</h2>
                <p className="text-base">{project.age}</p>
              </div>
              <div>
                <h2 className="text-2xl font-brik">Temporada</h2>
                <p className="text-base">{project.season}</p>
              </div>
            </div>

            {/* Botón */}
            {isActive(project.cta) ? (
              <button
                className="mt-8 w-full py-2 bg-gray text-blue font-mori shadow-md rounded-full"
                onClick={() => window.open(project.cta.url, "_blank")}
              >
                {project.cta.text}
              </button>
            ) : (
              ""
            )}
            {isNotClickable(project.cta) ? (
              <button className="mt-8 w-full py-2 bg-gray text-blue font-mori shadow-md rounded-full cursor-default">
                {project.cta.text}
              </button>
            ) : (
              ""
            )}
            {/* URL DE TICKETS  */}
          </div>
        </div>
        {/* Text sota imatge */}
        <div className="w-full border-l border-r border-b border-blu lg:flex flex-row">
          <div className="w-full lg:w-2/3 py-6 px-8 text-blue text-lg tracking-normal">
            <div
              className="html-content"
              dangerouslySetInnerHTML={{ __html: project.description }} // Renderiza HTML directamente
            />
          </div>
          <div className="w-full lg:w-1/3 py-6 px-8 text-blue text-sm tracking-normal">
            <h2 className="text-3xl tracking-wide text-blue font-brik pb-6 uppercase">
              tarifes i descomptes
            </h2>

            {/* Grid para los títulos y los precios */}
            <div className="grid grid-cols-5 text-lg font-bold pb-2">
              <span className="col-span-3"></span>
              <span className="text-center col-span-1">Platea</span>
              {hasDescomptes(project.pricing) ? (
                <span className="text-center col-span-1">Pis</span>
              ) : (
                ""
              )}
            </div>

            {/* Entrada General */}
            <div className="grid grid-cols-5 text-lg font-bold py-1">
              <span className="col-span-3">Entrada General</span>
              <span className="text-center col-span-1">
                {project.pricing.platea}
              </span>
              {hasDescomptes(project.pricing) ? (
                <span className="text-center col-span-1">
                  {project.pricing.pis}
                </span>
              ) : (
                ""
              )}
            </div>

            {/* Entrada Especial */}
            {hasDescomptes(project.pricing) ? (
              <div>
                <div className="grid grid-cols-5 text-base py-1 opacity-75">
                  <span className="col-span-3">
                    Entrada amb descompte (20%)
                  </span>
                  <span className="text-center col-span-1 ">12€</span>
                  <span className="text-center col-span-1">9,6€</span>
                </div>

                {/* Descripción */}
                <p className="text-sm font-mori opacity-75">
                  *Famílies nombroses i monoparentals <br />
                  *Grups (+15 persones)
                </p>

                {/* Información adicional */}
                <p className="text-base font-mori mt-4">
                  Per a més informació consulta el{" "}
                  <a
                    className="underline"
                    href="https://www.joventutdelafarandula.cat/venda-entrades"
                  >
                    següent enllaç
                  </a>
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <FitxaArtistica casting={project.casting} />
        {project.gallery ? <Gallery images={project.gallery} /> : null}
        {project.sponsors ? <Sponsors sponsors={project.sponsors} /> : null}
      </div>
    );
}

export default ProjectDetailComponent
